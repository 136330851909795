<template>
	<div class="order_wrap">
		<v-container class="order_home px-6">
			<div class="txt_box mt-8">
				<p>
					<span class="txt_body1" v-if="custType === 'L'">
						{{ name }} 고객님의 계약서입니다. 계약서 열람 비밀번호는 사업자번호
						10자리입니다. ex. 307-81-06054 (숫자만 입력)
					</span>
					<span class="txt_body1" v-else>
						{{ name }} 고객님의 계약서입니다. 계약서 열람 비밀번호는 생년월일
						6자리입니다. ex. 890502
					</span>
				</p>
			</div>
			<div class="button_box  mt-9">
				<div class="tit_03">계약서 목록</div>
				<template v-for="(ord, i) in orderList">
					<div
						class="list_vertical_01"
						:key="i"
						@click="downloadEc(ord)"
						v-if="
							orderList.length === orderStatusList.length &&
								orderStatusList[i].ORDER_STATUS !== '203'
						"
					>
						<div class="label">주문번호 {{ ord.orderNo }}</div>
						<div class="btn_type_01">
							<p class="btn_tit">
								<strong class="primary--text">{{ ord.sellType }}</strong>
								{{ ord.prdNameKr }}
							</p>
							<span class="txt_body1">
								{{ ord.modelName }}, {{ ord.prdName }}
							</span>
						</div>
					</div>
				</template>
			</div>
		</v-container>
	</div>
</template>
<script>
import {
	chkAuthExpired,
	fetchOrderConfirm,
	fetchEContract
} from '@/apis/order.ec.api'
import { getPrdName } from '@/utils/ec'
import { checkingStatus } from '@/apis/order.status.api'
export default {
	data() {
		return {
			name: '',
			orderList: [],
			orderStatusList: [],
			custType: ''
		}
	},
	async mounted() {
		const { userKey } = this.$route.query
		const res = await chkAuthExpired({ userKey })

		if (res.resultObject.valid) {
			this.validDate = res.resultObject.expireDateTime

			const info = res.resultObject.orderInfo
			const json = JSON.parse(info.orderInfoJson)
			this.custType = json.customer.custType

			if (this.custType === 'L') {
				this.name = json.customer.bizName
			} else {
				this.name = json.customer.name
			}

			const ecRes = await fetchEContract({ mobOrderNo: info.id })
			const orderNoList = ecRes.resultObject.data[0].setup

			if (json.setupInfoList) {
				orderNoList.forEach(ordNo => {
					let ord = {}
					ord.orderNo = ordNo.ORDER_NO
					ord.prdName = ordNo.GOODS_NM
					ord.prdNameKr = this.getPrdName(ordNo.PRODH9)
					ord.sellType = ordNo.E_SALE_GUBUN
					ord.modelName = this.getModelName(ordNo, json.setupInfoList)
					this.orderList.push(ord)
				})
			}
			//주문상태조회
			await this.createOrderStatusList(json.customer.custNo)
		} else {
			this.$router.push({ name: 'ec-cust-expired' })
		}
	},
	methods: {
		getPrdName,
		getModelName(ord, setupInfoList) {
			let name = ''
			setupInfoList.forEach(setup => {
				if (ord.GOODS_NM !== undefined) {
					if (ord.GOODS_NM.indexOf(setup.pay.prd.prod_nm) > -1) {
						name = setup.pay.prd.leader_model_nm
					}
				}
			})
			return name
		},
		async downloadEc(ord) {
			let params = {
				orderNo: ord.orderNo
			}
			const res = await fetchOrderConfirm(params)
			const link = `${process.env.VUE_APP_NEW_SIGN_URL}/smarteform/reqClientDown/pdfDown.jcg?filePath=${res.resultObject.data.ET_ZSDS5232[0].ZPDF_PATH}&fileName=ENC_${res.resultObject.data.ET_ZSDS5232[0].ZPDF}`
			this.$log(link)
			location.href = link
		},
		async createOrderStatusList(custNo) {
			const orderNoList = []
			for (const order of this.orderList) {
				orderNoList.push(order.orderNo)
			}

			const response = await checkingStatus({
				saleCd: this.$store.getters['common/codyNo'],
				kunnr: custNo,
				orderNos: orderNoList
			})
			this.orderStatusList = response.resultObject.T_ORDER_STATUS
		}
	}
}
</script>
